import moment from "moment/moment";
import React, { useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriberOrdersAsync } from "../../redux/subscriberSlice";

const MyOrders = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.subscribers);
  useEffect(() => {
    dispatch(getSubscriberOrdersAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const variantInfo = (variant) => {
    const variantKeys = Object.keys(variant);

    if (variantKeys.length === 1) {
      return (
        <div>
          <small>{variantKeys[0]}</small>
          <small>: </small>
          <small>{[variant[variantKeys[0]]]}</small>
        </div>
      );
    }
    return (
      <div>
        <div>
          <small>{variantKeys[0]}</small>
          <small>: </small>
          <small>{[variant[variantKeys[0]]]}</small>
        </div>
        <div>
          <small>{variantKeys[1]}</small>
          <small>: </small>
          <small>{[variant[variantKeys[1]]]}</small>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Container>
        <section>
          <h1>My Orders</h1>
        </section>

        {orders &&
          orders.map((order, key) => (
            <section className="orderWrapper" key={key}>
              <section key={order._id} className="flex-between  p-2">
                <div>{moment(order.createdAt).format("lll")}</div>
                <div>
                  <strong>Status : </strong>{" "}
                  {order.deliveryStatus.replaceAll("_", " ")}{" "}
                </div>
              </section>
              <Table>
                <thead className="myOrderTable">
                  <tr>
                    <th>S.N</th>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Sub-Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orderItems.map((item, index) => (
                    <tr key={item._id}>
                      <td className="myOrderTable">{index + 1}</td>
                      <td>
                        <div className="imageHolder">
                          <figure>
                            <img
                              src={`${process.env.REACT_APP_IMAGE_PREFIX}/${item.productImage}`}
                              alt=""
                            />
                          </figure>
                          <div className="imageHolder-text">
                            <div>{item.productName}</div>
                            <div className="itemInfo-info-brand ">
                              {item.variant ? variantInfo(item.variant) : null}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{item.quantity}</td>
                      <td className="myOrderTable">
                        ${item.productPrice.toFixed(2)}
                      </td>
                      <td>
                        ${(item.quantity * item.productPrice).toFixed(2)}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="text-end me-3">
                <span>
                  <strong>Total: ${order.grandTotal.toFixed(2)}</strong>
                </span>
                <div>with Discount</div>
              </div>
            </section>
          ))}
      </Container>
    </div>
  );
};

export default MyOrders;
