import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className='movfooter'></div>
      <section role='Footer'>
        <Container>
          <Row className='justify-content-between'>
            <Col md={4}>
              <section className='foooterInfo'>
                <header className='Links-title'>
                  <h4>About Us</h4>
                </header>
                <p>
                  Welcome to The Beauty Hub Nepal, where beauty meets
                  convenience and your individuality takes center stage! Dive
                  into a carefully curated selection of high-quality cosmetics,
                  embracing the latest makeup trends and skincare essentials.
                  We're your beauty companion, offering a hassle-free shopping
                  experience with fast shipping and responsive customer support.
                  Join us on a genuine beauty journey – where each purchase is
                  an authentic celebration of your unique style. Welcome to a
                  world where glamour is as real as you are!
                </p>
              </section>
            </Col>
            <Col md={2}>
              <section className='Links'>
                <header className='Links-title'>
                  <h4>Links</h4>
                </header>

                <ul className='Links-box'>
                  <li className='Links-box-item'>
                    <Link
                      to='/'
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='Links-box-item'>
                    <Link
                      to='/cart'
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      Cart
                    </Link>
                  </li>

                  <li className='Links-box-item'>
                    <Link
                      to='/terms-conditions'
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      Terms and conditons
                    </Link>
                  </li>
                  <li className='Links-box-item'>
                    <Link
                      to='/privacy-policy'
                      style={{ color: '#000', textDecoration: 'none' }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={3}>
              <section className='contact'>
                <header className='contact-title'>
                  <h4>Customer Support</h4>
                </header>

                <ul className='contact-box'>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-map-marker'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>Location</div>
                      <div className='addHolder-value '>
                        Amarpath , Butwal -05, Nepal
                      </div>
                    </div>
                  </li>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-phone'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>Phone</div>
                      <div className='addHolder-value '>9857028409</div>
                    </div>
                  </li>

                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-envelope'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>Email</div>
                      <div className='addHolder-value '>
                        <a href='mailto:manoj.sdhoju@gmail.com'>
                          <span style={{ color: 'black' }}>
                            manoj.sdhoju@gmail.com
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={3}>
              <section className='socialMedia'>
                <header className='socialMedia-title'>
                  <h4>Find Us On</h4>
                </header>

                <section className='socialWrapper'>
                  <a
                    href='https://www.facebook.com/profile.php?id=61553630349276'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-facebook'></i>
                    </div>
                  </a>

                  <a
                    href='https://www.instagram.com/thebeautyhub.nepal'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-instagram'></i>
                    </div>
                  </a>
                </section>

                <header className='socialMedia-title'>
                  <h4>We Accept</h4>
                </header>
                <i className='fa fa-whatsapp whattsappIcon'></i>
              </section>
            </Col>
          </Row>

          <hr />
          <section className='copyright'>
            The Beauty Hub Nepal &copy; {new Date().getFullYear()} All Rights
            Reserved
          </section>
        </Container>
      </section>
    </>
  );
};

export default Footer;
