import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

function TermsConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className='mt-5'>
      <h1>**Terms of Use**</h1>
      Before accessing thebeautyhubnepal.com, please review the Terms and
      Conditions carefully. This digital document is generated by a computer
      system and does not require any physical or digital signatures.
      <h2 className='mt-5'>**Introduction**</h2>
      Welcome to thebeautyhubnepal.com. We specialize in importing,
      distributing, and retailing makeup and skincare products. Through this
      online platform, we aim to cater to your needs conveniently. By using the
      Site, you agree to these terms and conditions and affirm that you will
      adhere to them. If you do not consent to this Agreement, please refrain
      from accessing, registering, or using this Site. The Site reserves the
      right to modify, add, or remove portions of these Terms and Conditions at
      any time without prior notice. Changes become effective upon posting on
      the Site without additional notification. Please check these Terms and
      Conditions regularly for updates. Your continued use of the Site following
      changes constitutes your acceptance of those changes. You are responsible
      for maintaining the confidentiality of your account details and related
      private information. Notify us immediately if you suspect unauthorized
      access or use of your account. By accessing the Site or using any
      services, you agree to be bound by any actions performed through your
      account and to indemnify us against any losses arising from such actions.
      Ensure that your account details are accurate and up-to-date. We reserve
      the right to refuse access, terminate accounts, or edit content without
      prior notice. We may request updates to your Personal Data or invalidate
      accounts at our discretion without liability for any resulting losses.
      <h2 className='mt-5'>**Your Conduct**</h2>
      You must not use the website in any way that disrupts or damages its
      functionality or access. Engaging in activities that harm or potentially
      harm the Site, its stakeholders, or associated parties is prohibited. You
      are solely responsible for all electronic communications sent from your
      device to us and must use the Site lawfully. You are strictly prohibited
      from using the Site for fraudulent purposes or engaging in unlawful
      activities. Prohibited actions include sending or using unauthorized
      material, engaging in offensive behavior, impersonating others, or
      threatening the security of the Site.
      <h2 className='mt-5'>**Your Submissions**</h2>
      Any submissions to the Site become our property and may be used at our
      discretion. By posting comments or reviews, you grant us the right to use
      your submitted name in connection with the content. Do not submit false
      information or mislead us or third parties regarding the origin of
      submissions. We reserve the right to remove or edit any submissions
      without notice.
      <h2 className='mt-5'>**Disclaimer**</h2>
      You acknowledge that you access the Site and engage in transactions at
      your own risk. We are not liable for disputes between you and product
      manufacturers or service providers listed on our Site, including payment
      providers or warranty services.
    </Container>
  );
}

export default TermsConditions;
