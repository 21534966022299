import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const { id, token } = useParams();
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState(false);

  const verifyUser = async () => {
    try {
      const response = await axios.put(
        `/api/subscribers/verify-user/${id}/${token}`
      );
      if (response.status === 200) {
        setVerifying(true);
      }
    } catch (err) {
      setError(true);
    }
  };
  useEffect(() => {
    if (verifying) {
      navigate("/");
    }
    verifyUser();
  }, [verifying]);
  return error ? (
    <div className="text-center mt-5 cda">Invalid verification token</div>
  ) : (
    <div className="text-center mt-5 csu">Verifying</div>
  );
};

export default Index;
