import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getSubscriberDetailsAsync, logout } from '../../redux/subscriberSlice';

const More = () => {
  const [login, setLogin] = useState(true);
  const navigate = useNavigate();
  const { user, userInfo, isError, message } = useSelector(
    (state) => state.subscribers
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!userInfo) {
      if (isError && message === 'Not authorize, token failed') {
        dispatch(logout());
      }
      dispatch(getSubscriberDetailsAsync());
    }
  }, [user]);

  return (
    <div>
      <section className='moreContainer'>
        <ul>
          <li>
            {user ? (
              <section>
                <Link to='/account'>
                  <div>
                    <i className='fa fa-user-circle-o'></i> My Account
                  </div>
                  <small>{user.name}</small>
                </Link>
              </section>
            ) : (
              <Link to='/login'>
                <i className='fa  fa-sign-in'></i> Login
              </Link>
            )}
          </li>
          <li>About Us </li>
          <li>Terms and conditions</li>
          <li>Privacy Policy</li>
          {user && (
            <li
              onClick={(e) => {
                e.preventDefault();
                dispatch(logout());
                navigate('/');
              }}
            >
              Logout
            </li>
          )}
        </ul>
      </section>
    </div>
  );
};

export default More;
