import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Modal,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Bill from "../../components/Bill/index";
import paymentAcceptance from "../../assets/images/payment.png";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  addOrderAsync,
  changeShippingDetails,
  getBuyNowCartUpdateDetailsAsync,
  getCartUpdateDetailsAsync,
  removeCart,
  resetQuickBuy,
} from "../../redux/cartSlice";
import axios from "axios";
import Message from "../../components/Message/Message";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userDetails, user } = useSelector((state) => state.subscribers);
  const {
    shippingDetails: sDetails,
    billingDetails: bDetails,
    buyNowTotal,
    buyNowCartItems,
    buyNowGrandTotal,
    total,
    cartItems,
    ...cart
  } = useSelector((state) => state.cart);

  const [show, setShow] = useState(false);
  const [details, setDetails] = useState(
    JSON.parse(localStorage.getItem("details"))
  );
  const [shippingDetails, setShippingDetails] = useState(null);
  const [billingDetails, setBillingDetails] = useState(null);

  useEffect(() => {
    !details &&
      location.pathname.includes("buy-now") &&
      navigate("/shipping/buy-now");
    !details && !location.pathname.includes("buy-now") && navigate("/shipping");
  }, [details]);

  const handleClose = () => {
    setShow(false);
    navigate("/");
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!sDetails || !bDetails) {
      dispatch(
        changeShippingDetails({
          shippingDetails: details?.details?.shippingDetails,
          billingDetails: details?.details?.billingAddress,
        })
      );
    }
  }, [dispatch, sDetails, bDetails]);

  useEffect(() => {
    if (cart?.isOrderPlaceSuccess) {
      setShow(true);
      dispatch(removeCart());
    }
  }, [cart?.isOrderPlaceSuccess]);
  useEffect(() => {
    if (cart?.quickBuySuccess) {
      setShow(true);
      dispatch(resetQuickBuy());
    }
  }, [cart?.quickBuySuccess]);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      if (!user) {
        navigate("/login");
        return;
      } else {
        dispatch(
          addOrderAsync({
            order: {
              details: { billingDetails: bDetails, shippingDetails: sDetails },
              cart: !location.pathname.includes("buy-now")
                ? { cartItems, quickBuy: false }
                : {
                    cartItems: buyNowCartItems,
                    quickBuy: true,
                  },
            },
          })
        );
      }
    }
  }, [success, dispatch]);
  useEffect(() => {
    const fetch = async () => {
      const response = await axios.post("/api/orders/create-payment", {
        order: {
          cart: !location.pathname.includes("buy-now")
            ? { cartItems: cartItems, quickBuy: false }
            : {
                cartItems: buyNowCartItems,
                quickBuy: true,
              },
        },
      });

      const { paymentId } = response.data;

      if (paymentId) {
        const checkoutOptions = {
          checkoutKey: "test-checkout-key-2f1ba1216bec444097a771182af55c1c", // Replace!
          paymentId: paymentId,
          containerId: "checkout-container-div",
        };
        // eslint-disable-next-line no-undef
        const checkout = new Dibs.Checkout(checkoutOptions);
        checkout.on("payment-completed", function (response) {
          setSuccess(true);
        });
      } else {
        // No paymentId provided,
        // window.location = 'https://sunrisetest.netlify.app/cart'; // go back to cart.html
        // window.location = 'http://sunrisetest.netlify.app/cart'; // go back to cart.html
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("buy-now") && cartItems.length) {
      dispatch(getCartUpdateDetailsAsync({ cart: { cartItems } }));
    }
  }, []);
  useEffect(() => {
    if (location.pathname.includes("buy-now") && buyNowCartItems?.length) {
      dispatch(
        getBuyNowCartUpdateDetailsAsync({
          buyNowCart: { buyNowCartItems },
        })
      );
    }
  }, []);

  return (
    <>
      <section className="shippingWrapper">
        <Container>
          <Row>
            <Col md={7}>
              <section className="shippingForm ">
                <section className="mt-3">
                  <Row>
                    <section className="paymentMethod">
                      <h2> Payment Method</h2>
                      <div className="text-muted">
                        All transactions are secure and encrypted
                      </div>

                      <img
                        src={paymentAcceptance}
                        alt=""
                        style={{ width: "10rem", marginTop: "1rem" }}
                      />
                      {<div id="checkout-container-div"></div>}
                      {/* <section className='mt-2'>
                        <Form>
                          <Row>
                            <Col md={12}>
                              <Form.Group
                                className='mb-3'
                                controlId='formBasicEmail'
                              >
                                <Form.Label>Card Number</Form.Label>
                                <Form.Control type='text' />
                              </Form.Group>
                            </Col>
                            <Col md={12}>
                              <Form.Group
                                className='mb-3'
                                controlId='formBasicEmail'
                              >
                                <Form.Label>Name on Card</Form.Label>
                                <Form.Control type='text' />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className='mb-3'
                                controlId='formBasicEmail'
                              >
                                <Form.Label>Expiration Date</Form.Label>
                                <Form.Control type='text' placeholder='MM/YY' />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className='mb-3'
                                controlId='formBasicEmail'
                              >
                                <Form.Label>Security Code</Form.Label>
                                <Form.Control type='text' />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      </section> */}
                    </section>
                  </Row>
                </section>
              </section>
            </Col>

            <Col>
              <Bill />
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section className="text-center">
            <div>
              <i
                className="fa  fa-angellist mb-3 "
                style={{ color: "green", fontSize: "3.5rem" }}
              ></i>
            </div>
            <strong>Your Order Has been Placed Sucessfully!!!</strong>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bton bton--full bton--primary"
            onClick={handleClose}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Checkout;
