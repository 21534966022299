import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import ImageGallery from "react-image-gallery";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearProduct, getProductDetailAsync } from "../../redux/productSlice";
import ProductCard from "../../components/ProductCard";

import ItemSkeleton from "../../components/Skeletons/ItemSkeleton";
import { addToCart, resetCartAdd } from "../../redux/cartSlice";

function Index() {
  const [more, setMore] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();
  const product = useSelector((state) => state.products.product);
  const loading = useSelector((state) => state.products.loading);
  const cartAdding = useSelector((state) => state.cart.cartAddSuccess);
  const [qty, setQty] = useState(1);

  const [outOfStock, setOutOfStock] = useState(false);
  const [outOfStockMsg, setoutOfStockMsg] = useState("");
  const [orderLimit, setOrderLimit] = useState(false);
  const [variants, setVariants] = useState("");

  useEffect(() => {
    if (product) {
      if (!variants) {
        setVariants(product.variants);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [params]);

  useEffect(() => {
    dispatch(getProductDetailAsync({ slug: params.slug, params: {} }));
    setQty(1);

    return () => {
      dispatch(clearProduct());
    };
  }, [dispatch, params]);

  useEffect(() => {
    if (product) {
      if (product.orderLimit <= product.stock) {
        if (qty === product.orderLimit) {
          setOrderLimit(true);
        } else {
          setOrderLimit(false);
        }
      } else {
        if (qty === product.stock) {
          setOutOfStock(true);
        } else {
          setOutOfStock(false);
        }
      }
    }
  }, [qty, product]);

  useEffect(() => {
    if (outOfStockMsg) {
      setTimeout(() => {
        setoutOfStockMsg("");
      }, 2000);
    }
  }, [outOfStockMsg]);

  const handleVariant = ({ type, choice }) => {
    if (variants[type] !== choice) {
      dispatch(
        getProductDetailAsync({
          slug: params.slug,
          params: { ...variants, [type]: choice },
        })
      );
      setVariants((prev) => ({ ...prev, [type]: choice }));
      setQty(1);
      setoutOfStockMsg("");
    }
  };

  if (!product && loading) return <ItemSkeleton />;

  return (
    <Fragment>
      {product && (
        <Container>
          <section className=" productWrapper mt-3">
            <Row>
              <Col lg={5}>
                <section className="imageHolder">
                  {product?.gallery.length > 0 ? (
                    <ImageGallery
                      items={product.gallery.map((g) => ({
                        original: `${process.env.REACT_APP_IMAGE_PREFIX}${g}`,
                        thumbnail: `${process.env.REACT_APP_IMAGE_PREFIX}${g}`,
                      }))}
                      showPlayButton={false}
                      thumbnailPosition="bottom"
                    />
                  ) : (
                    <img
                      className="singleImageProduct"
                      src={`${process.env.REACT_APP_IMAGE_PREFIX}/${product.heroImage}`}
                      alt={product.name}
                    />
                  )}
                </section>
              </Col>
              <Col lg={7}>
                <section className="DetailsHolder">
                  <section className="DetailsHolder-header ">
                    <h1 className="DetailsHolder-header-title">
                      {product.name}
                    </h1>
                    <div className="DetailsHolder-header-brand mb-3">
                      <span className="brandKey">Category: </span>{" "}
                      <span className="brandValue">{product.category}</span>
                    </div>
                  </section>

                  <Row>
                    <Col>
                      <section className="DetailsHolder-stock mt-3">
                        <span className="DetailsHolder-stock-price">
                          Rs. {product.sellingPrice}
                        </span>
                        {product.discount > 0 && (
                          <>
                            <span className="DetailsHolder-stock-cutprice ml-3">
                              Rs. {product.price}
                            </span>
                            <div className="DetailsHolder-stock-discount ml-3">
                              {product.discount}% off
                            </div>
                          </>
                        )}
                        <div className="DetailsHolder-stock-vatInfo">
                          incl. of all tax
                        </div>
                        <div
                          className={`${
                            product.stock > 0
                              ? "DetailsHolder-stock-stockInfo mt-1"
                              : "cda mt-1"
                          }`}
                        >
                          {product.stock > 0 ? "IN STOCK" : "OUT OF STOCK"}
                        </div>
                      </section>
                      <div className="DetailsHolder-variants">
                        {product.options
                          ? product.options.map((entry) => (
                              <div
                                className="DetailsHolder-variants_item"
                                key={entry.name}
                              >
                                <span className="DetailsHolder-variants_item-name">
                                  {entry.name}
                                </span>
                                <div className="btons-list">
                                  {entry.values.map((choice) => (
                                    <button
                                      key={choice}
                                      type="button"
                                      className={`btons-list_item ${
                                        variants[entry.name] === choice
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleVariant({
                                          type: entry.name,
                                          choice: choice,
                                        })
                                      }
                                    >
                                      {choice}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                      {product.stock > 0 && (
                        <>
                          <section className="DetailsHolder-qty mt-3">
                            <div className="DetailsHolder-qty-name">
                              Quantity
                            </div>

                            <div className="counterHolder">
                              <div
                                className="counterHolder-item"
                                onClick={() => {
                                  if (Number(qty) > 1) {
                                    setQty(qty - 1);
                                    setoutOfStockMsg("");
                                  }
                                }}
                              >
                                -
                              </div>
                              <div className="counterHolder-item">{qty}</div>
                              <div
                                className="counterHolder-item"
                                onClick={() => {
                                  if (
                                    qty < product.stock &&
                                    qty < product.orderLimit
                                  ) {
                                    setQty(qty + 1);
                                    setoutOfStockMsg("");
                                  } else if (outOfStock) {
                                    setoutOfStockMsg("Stock limit reached");
                                  } else if (orderLimit) {
                                    setoutOfStockMsg("Order limit reached");
                                  }
                                }}
                              >
                                +
                              </div>
                            </div>
                          </section>
                          <section className="DetailsHolder-cta mt-4 pb-4">
                            <button
                              disabled={product.stock < 1}
                              className="bton bton--md bton--primary prodbuttons"
                              onClick={() => {
                                if (qty > 0) {
                                  dispatch(
                                    addToCart({
                                      product: {
                                        _id: product._id,
                                        name: product.name,
                                        slug: product.slug,
                                        stock: product.stock,
                                        orderLimit: product.orderLimit,
                                        heroImage: product.heroImage,
                                        price: product.price,
                                        discount: product.discount,
                                        sellingPrice: product.sellingPrice,
                                        variants: product.variants,
                                      },
                                      qty,
                                    })
                                  );
                                  setTimeout(() => {
                                    dispatch(resetCartAdd());
                                  }, 1000);
                                }
                              }}
                            >
                              <i className="fa  fa-shopping-cart"></i> Add to
                              cart
                            </button>
                            {cartAdding && (
                              <div className="bsut csu mt-3 addedSuccess">
                                Product Added Successfully !!
                              </div>
                            )}
                            {outOfStockMsg ? (
                              <div className="bdat cda mt-3 addedSuccess">
                                {outOfStockMsg}
                              </div>
                            ) : null}
                          </section>
                        </>
                      )}
                    </Col>
                  </Row>
                </section>
              </Col>
            </Row>
          </section>

          <Container style={{ marginTop: "3rem" }}>
            <Row>
              <Col lg={6}>
                <div className=" productTechInfo">
                  <section className="productTechDesc ">
                    <h2 className="mt-3">Product Specifications</h2>
                    <ul className="mt-3">
                      {product.information &&
                        product.information.map((info, key) => {
                          return (
                            <li className="productTechDesc-box mt-2 " key={key}>
                              <div className="productTechDesc-box-key ">
                                {info.informationKey}
                              </div>{" "}
                              <div className="productTechDesc-box ">
                                {info.informationValue}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </section>
                </div>
              </Col>
              <Col lg={6}>
                <div className=" productInfoWrapper">
                  <section
                    className="productDesc "
                    style={{ height: `${more ? "100%" : "24.5rem"}` }}
                  >
                    <h2 className="mt-3">Description</h2>
                    <p
                      className="mt-3"
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  </section>

                  {product.description.length > 500 ? (
                    <button
                      onClick={() => setMore(!more)}
                      className="bton bton--nacked mt-3"
                    >
                      {more ? "show less" : "show more ..."}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
            {product.related.length > 0 && (
              <>
                <h1 className="text-center m-5">Related Products</h1>

                <Row>
                  {product.related.map((rp, key) => (
                    <ProductCard column="3" fProduct={rp} key={key} />
                  ))}
                </Row>
              </>
            )}
          </Container>
        </Container>
      )}
    </Fragment>
  );
}

export default Index;
