import React, { Fragment } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import Bill from "../../components/Bill/index";
import {
  deleteCartItem,
  removeFromCart,
  removeCart,
  addToQuickBuyLoading,
  addToQuickBuySuccess,
  getCartUpdateDetailsAsync,
  getBuyNowCartUpdateDetailsAsync,
  resetQuickBuy,
  addToCart,
  removeFromBuyNowCart,
} from "../../redux/cartSlice";
import NoItems from "../../components/NoItems";

function Index() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const {
    cartItems,
    buyNowCartItems,
    total,
    buyNowTotal,
    cartAddLoading,

    getBuyNowCartLoading,

    cartError,
    cartMessage,
    buyNowCartError,
    buyNowCartMessage,
  } = useSelector((state) => state.cart);
  useEffect(() => {
    if (!location.pathname.includes("buy-now") && cartItems.length) {
      dispatch(getCartUpdateDetailsAsync({ cart: { cartItems } }));
      setErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);
  useEffect(() => {
    if (location.pathname.includes("buy-now") && buyNowCartItems?.length) {
      dispatch(
        getBuyNowCartUpdateDetailsAsync({
          buyNowCart: { buyNowCartItems },
        })
      );
      setErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyNowTotal]);

  const variantInfo = (variant) => {
    const variantKeys = Object.keys(variant);

    if (variantKeys.length === 1) {
      return (
        <div>
          <small>{variantKeys[0]}</small>
          <small>: </small>
          <small>{[variant[variantKeys[0]]]}</small>
        </div>
      );
    }
    return (
      <div>
        <div>
          <span>{variantKeys[0]}</span>
          <span>: </span>
          <span>{[variant[variantKeys[0]]]}</span>
        </div>
        <div>
          <span>{variantKeys[1]}</span>
          <span>: </span>
          <span>{[variant[variantKeys[1]]]}</span>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {!location.pathname.includes("buy-now") ? (
        <section>
          <div className="container checkoutWrapper">
            {!cartItems.length ? (
              <NoItems />
            ) : (
              <Row>
                <Col lg={8} md={12}>
                  <section className="cartItem">
                    <section className="flex-between">
                      <h2 className="">Your Cart</h2>{" "}
                      <button
                        className="bton bton--nacked bton--sm"
                        onClick={() => dispatch(removeCart())}
                      >
                        clear cart
                      </button>
                    </section>
                    <section className="cartItem-title pb-2">
                      <div className="container p-0">
                        <div className="row mt-3">
                          <div className="col-6">
                            PRODUCT (<strong>{cartItems?.length}</strong>)
                          </div>
                          <div className="col-2">QUANTITY</div>
                          <div className="col-2">UNIT COST</div>
                          <div className="col-2">SUB-TOTAL</div>
                        </div>
                      </div>
                    </section>
                    {/* .................  */}
                    {!cartAddLoading ? (
                      <>
                        {cartItems?.map((item, key) => (
                          <section key={key} className="cartItem-items ">
                            <div className="container p-0">
                              <div className="row mt-3">
                                <div className="col-md-6 col-sm-12">
                                  <section className="itemInfo">
                                    <figure className="itemInfo-image">
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_PREFIX}/${item.product.heroImage}`}
                                        alt={item.product.name}
                                      />
                                    </figure>
                                    <section className="itemInfo-info">
                                      <p className="itemInfo-info-title">
                                        {item.product.name}
                                      </p>
                                      <div className="itemInfo-info-brand ">
                                        {item.product.variants
                                          ? variantInfo(item.product.variants)
                                          : null}
                                      </div>

                                      <div
                                        className="itemInfo-info-remove mt-2"
                                        onClick={() =>
                                          dispatch(
                                            deleteCartItem(
                                              item.product._id.toString()
                                            )
                                          )
                                        }
                                      >
                                        Remove
                                      </div>
                                      <div>
                                        {
                                          errors.find((i) => {
                                            return (
                                              i.product_id === item.product._id
                                            );
                                          })?.message
                                        }
                                      </div>
                                    </section>
                                  </section>
                                </div>

                                <div className="col-md-2 col-4">
                                  {/* <Counter />
                                  <QuantityBox
                                    id={item.product._id}
                                    number={item.qty}
                                    item={item}
                                    loading={cartAddLoading}
                                  /> */}

                                  <div className="counterHolder">
                                    <div
                                      className="counterHolder-item"
                                      onClick={() => {
                                        dispatch(
                                          removeFromCart(item.product._id)
                                        );
                                      }}
                                    >
                                      -
                                    </div>

                                    <div className="counterHolder-item">
                                      {item.qty}
                                    </div>
                                    <div
                                      className="counterHolder-item"
                                      onClick={() => {
                                        dispatch(
                                          addToCart({
                                            product: item.product,
                                            qty: 1,
                                          })
                                        );
                                      }}
                                    >
                                      +
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-4">
                                  <div className="cartItem-items-unit ">
                                    RS. {item.product.price}
                                  </div>{" "}
                                </div>
                                <div className="col-md-2 col-4">
                                  <div className="cartItem-items-sub">
                                    Rs.{" "}
                                    {(item.product.price * item.qty).toFixed(2)}
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          </section>
                        ))}
                      </>
                    ) : (
                      <div className="overlayLoader">
                        <span className="overlayLoader-item">
                          <Spinner animation="border" variant="light" />{" "}
                          <span>Loading</span>
                        </span>
                      </div>
                    )}

                    {/* .............////// */}
                  </section>
                  {!location.pathname.includes("buy-now") && cartError && (
                    <p className="loginError">{cartMessage}</p>
                  )}
                </Col>
                <Col lg={4} md={12}>
                  <Bill show={true} errors={errors} setErrors={setErrors} />
                </Col>
              </Row>
            )}

            <Link to="/">
              <section className="continue mt-3">
                {" "}
                <i className="fa  fa-chevron-left"></i> continue shopping
              </section>
            </Link>
          </div>
        </section>
      ) : (
        <section>
          {buyNowCartItems?.length > 0 ? (
            <div className="container checkoutWrapper">
              <Row>
                <Col lg={8} md={12}>
                  <section className="cartItem">
                    <section className="flex-between">
                      <h2 className="">Your Cart</h2>{" "}
                      <button className="bton bton--nacked bton--sm">
                        clear cart
                      </button>
                    </section>
                    <section className="cartItem-title pb-2">
                      <div className="container p-0">
                        <div className="row mt-3">
                          <div className="col-6">
                            PRODUCT (<strong>1</strong>)
                          </div>
                          <div className="col-2">QUANTITY</div>
                          <div className="col-2">UNIT COST</div>
                          <div className="col-2">SUB-TOTAL</div>
                        </div>
                      </div>
                    </section>
                    {/* .................  */}
                    {getBuyNowCartLoading ? (
                      <div className="overlayLoader">
                        <span className="overlayLoader-item">
                          <Spinner animation="border" variant="light" />{" "}
                          <span>Loading</span>
                        </span>
                      </div>
                    ) : (
                      <section className="cartItem-items ">
                        <div className="container p-0">
                          <div className="row mt-3">
                            <div className="col-6">
                              <section className="itemInfo">
                                <figure className="itemInfo-image">
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_PREFIX}/${buyNowCartItems[0]?.product.heroImage}`}
                                    alt=""
                                  />
                                </figure>
                                <section className="itemInfo-info">
                                  <p className="itemInfo-info-title">
                                    {buyNowCartItems[0]?.product.name}
                                  </p>
                                  {/* <div className='itemInfo-info-brand '>
                                <span className='brandKey'>Brand:</span>{' '}
                                <span className='brandValue'>
                                  {' '}
                                  <strong>Mens Wear </strong>{' '}
                                </span>
                              </div> */}

                                  <div
                                    className="itemInfo-info-remove mt-2"
                                    onClick={() => {
                                      dispatch(resetQuickBuy());
                                    }}
                                  >
                                    Remove
                                  </div>
                                  {errors && errors[0]?.message}
                                </section>
                              </section>
                            </div>
                            {/* <div className='col-2'>
                              <QuantityBox
                                id={buyNowCartItems[0]?.product._id}
                                number={buyNowCartItems[0]?.qty}
                                item={buyNowCartItems[0]}
                              />
                            </div> */}
                            <div className="col-md-2 col-4">
                              {/* <Counter />
                                  <QuantityBox
                                    id={item.product._id}
                                    number={item.qty}
                                    item={item}
                                    loading={cartAddLoading}
                                  /> */}
                              <div className="counterHolder">
                                {buyNowCartItems[0].qty > 1 && (
                                  <div
                                    className="counterHolder-item"
                                    onClick={() => {
                                      dispatch(
                                        removeFromBuyNowCart(
                                          buyNowCartItems[0]?.product._id
                                        )
                                      );
                                    }}
                                  >
                                    -
                                  </div>
                                )}
                                <div className="counterHolder-item">
                                  {buyNowCartItems[0].qty}
                                </div>
                                <div
                                  className="counterHolder-item"
                                  onClick={() => {
                                    dispatch(addToQuickBuyLoading());
                                    if (
                                      buyNowCartItems[0].qty + 1 <=
                                        buyNowCartItems[0]?.product.stock ||
                                      buyNowCartItems[0].qty + 1 <=
                                        buyNowCartItems[0]?.product.orderLimit
                                    )
                                      dispatch(
                                        addToQuickBuySuccess({
                                          product: buyNowCartItems[0]?.product,
                                          qty: buyNowCartItems[0]?.qty + 1,
                                        })
                                      );
                                  }}
                                >
                                  +
                                </div>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="cartItem-items-unit ">
                                RS. {buyNowCartItems[0]?.product.price}
                              </div>{" "}
                            </div>
                            <div className="col-2">
                              <div className="cartItem-items-sub">
                                Rs.{" "}
                                {(
                                  buyNowCartItems[0]?.product.price *
                                  buyNowCartItems[0]?.qty
                                ).toFixed(2)}{" "}
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                    {/* .............////// */}
                    {location.pathname.includes("buy-now") &&
                      buyNowCartError && (
                        <p className="loginError">{buyNowCartMessage}</p>
                      )}
                  </section>
                </Col>
                <Col lg={4} md={12}>
                  <Bill show={true} errors={errors} setErrors={setErrors} />
                </Col>
              </Row>

              <section className="continue mt-3">
                {" "}
                <Link to="/">
                  <i className="fa  fa-chevron-left"></i> continue shopping
                </Link>
              </section>
            </div>
          ) : (
            <div>
              <section className="continue mt-3">
                {" "}
                <NoItems />
                <Link to="/">
                  <i className="fa  fa-chevron-left"></i> continue shopping
                </Link>
              </section>
            </div>
          )}
        </section>
      )}
    </Fragment>
  );
}

export default Index;
