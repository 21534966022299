import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSubscriberDetailsAsync,
  logout,
  resetError,
  updateUserProfileAsync,
} from '../../redux/subscriberSlice';
import Message from '../Message/Message';
const MyModal = ({
  show,
  setShow,
  passwordbox,
  setPasswordbox,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const {
    isError,
    userDetails,
    message,
    updateSuccess,
    updateLoading,
    updatePasswordSuccess,
  } = useSelector((state) => state.subscribers);

  const [formData, setFormData] = useState({
    name: userDetails?.name,
    phone: userDetails?.phone,
    address: userDetails?.address,
    apartmentNumber: userDetails?.apartmentNumber,
    postalCode: userDetails?.postalCode,
    city: userDetails?.city,
  });
  useEffect(() => {
    setFormData({
      name: userDetails?.name,
      phone: userDetails?.phone,
      address: userDetails?.address,
      apartmentNumber: userDetails?.apartmentNumber,
      postalCode: userDetails?.postalCode,
      city: userDetails?.city,
    });
  }, []);
  const [passwordData, setPasswordData] = useState({
    previousPassword: '',
    newPassword: '',
    newPassword1: '',
  });
  const [phoneError, setPhoneError] = useState(null);
  const [previousPasswordError, setPreviousPasswordError] = useState(null);

  const [previousPassword, setPreviousPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [passwordValidationError, setPasswordValidationError] = useState(null);
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const passwordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (
      passwordbox &&
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/.test(
        passwordData.newPassword
      )
    ) {
      setPasswordValidationError(true);
      return;
    } else {
      if (passwordbox) {
        dispatch(
          updateUserProfileAsync({
            ...formData,
            ...passwordData,
            updatePassword: true,
          })
        );
      } else {
        dispatch(updateUserProfileAsync(formData));
      }
    }
  };
  useEffect(() => {
    if (isError && message === 'Old password does not match!') {
      setPreviousPasswordError(true);
    }
    if (isError && message === 'Passwords do not match!') {
      setPasswordMatchError(true);
    }
  }, [isError]);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(getSubscriberDetailsAsync());
      updatePasswordSuccess && dispatch(logout());
      handleClose();
    }
  }, [updateSuccess]);
  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setPasswordData({});
        setPasswordbox(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {passwordMatchError && (
          <Message success={false} setError={setPasswordMatchError}>
            {'Password does not match.'}
          </Message>
        )}
        {previousPasswordError && (
          <Message success={false} setError={setPreviousPasswordError}>
            {'Previous Password does not match.'}
          </Message>
        )}
        {passwordValidationError && (
          <Message success={false} setError={setPasswordValidationError}>
            {
              'Password must contain a combination of numbers, small letters, capital letters and special characters.'
            }
          </Message>
        )}
        <Form>
          <Row>
            <Col md={12}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Control
                  type='text'
                  placeholder='name'
                  name='name'
                  value={formData.name}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Control
                  type='text'
                  placeholder='phone number'
                  value={formData.phone}
                  name='phone'
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Control
                  type='text'
                  name='address'
                  placeholder='Street and House number'
                  value={formData.address}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Control
                  type='text'
                  name='apartmentNumber'
                  placeholder='Apartment/Suit'
                  value={formData.apartmentNumber}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Control
                  type='text'
                  placeholder=' postal code'
                  name='postalCode'
                  value={formData.postalCode}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Control
                  type='text'
                  placeholder='city'
                  name='city'
                  value={formData.city}
                  onChange={onChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <button
            className='bton bton--sm bton--nacked'
            onClick={(e) => {
              e.preventDefault();
              setPasswordbox(!passwordbox);
            }}
          >
            change password
          </button>

          {passwordbox ? (
            <>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Control
                  type='password'
                  value={passwordData.previousPassword}
                  name={'previousPassword'}
                  onChange={passwordChange}
                  placeholder=' Current Password'
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Control
                  type='password'
                  placeholder=' New Password'
                  name='newPassword'
                  value={passwordData.newPassword}
                  onChange={passwordChange}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Control
                  type='password'
                  placeholder=' Retype New Password'
                  name='newPassword1'
                  value={passwordData.newPassword1}
                  onChange={passwordChange}
                />
              </Form.Group>
            </>
          ) : null}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className='bton bton--md bton--primary' onClick={submitHandler}>
          Update
        </button>
        <button
          className='bton bton--md bton--ghost--danger'
          onClick={handleClose}
        >
          Discard
        </button>
      </Modal.Footer>
    </Modal>
  );
};
const MyDetails = () => {
  const [show, setShow] = useState(false);
  const [passwordbox, setPasswordbox] = useState(false);
  const { userDetails } = useSelector((state) => state.subscribers);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className='detailsWrapper'>
        <h1>My Details</h1>
        <div className='text-end'>
          <button
            className='bton bton--sm bton--nacked'
            onClick={() => setShow(true)}
          >
            edit
          </button>
        </div>
        <ListGroup>
          <ListGroup.Item>
            {' '}
            <strong>Name :</strong> {userDetails?.name}
          </ListGroup.Item>
          <ListGroup.Item>
            {' '}
            <strong>Phone :</strong> {userDetails?.phone}
          </ListGroup.Item>
          <ListGroup.Item>
            {' '}
            <strong>Email :</strong> {userDetails?.email}
          </ListGroup.Item>
          <ListGroup.Item>
            {' '}
            <strong>Address :</strong> {userDetails?.address}
          </ListGroup.Item>
        </ListGroup>
      </section>

      {userDetails && (
        <MyModal
          show={show}
          setShow={setShow}
          passwordbox={passwordbox}
          setPasswordbox={setPasswordbox}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default MyDetails;
