import React from "react";

import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import LoaderComponent from "../LoaderComponent";

function CategoryProductCard({ column, products, productLoading }) {
  if (productLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      {products &&
        products.map((pro, i) => {
          return (
            <Col md={column} key={i}>
              <Link to={`/product/${pro.slug}`}>
                <section className="productCard">
                  <figure className="productCard-image">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PREFIX}${
                        pro.heroImage === "" ? "/default.jpg" : pro.heroImage
                      }`}
                      alt={pro.name}
                    />
                  </figure>
                  <div className="productCard-title">{pro.name}</div>
                  <div className="productCard-price">
                    <span className="priceValue">Rs {pro.sellingPrice} </span>{" "}
                    <span className="stockValue">
                      {pro.stock > 0 ? "IN STOCK" : "OUT OF STOCK"}
                    </span>
                  </div>
                  {pro.discount > 0 && (
                    <span className="cardOff">{pro.discount}%off</span>
                  )}
                </section>
              </Link>
            </Col>
          );
        })}
    </>
  );
}

export default CategoryProductCard;
