import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

function PricacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className='mt-5'>
      "<h1>Privacy Policy</h1>
      Welcome to thebeautyhubnepal.com, operated by The Beauty Hub. We highly
      value your privacy and are committed to safeguarding your personal
      information. Please take a moment to review this Privacy Policy to
      understand how we collect, use, and, under certain circumstances, disclose
      your personal information. This policy also outlines the measures we take
      to ensure the security of your personal information. Additionally, it
      explains your options regarding the collection, use, and disclosure of
      your personal information. By accessing the Site directly or through
      another site, you agree to the practices outlined in this Policy. Please
      note that our Policy may be updated from time to time without prior
      notice. Your continued use of the Site after such changes constitutes your
      acceptance of the revised Policy. If you choose not to continue using the
      Site following a change in the policy, but do not take action regarding
      your personal information and sensitive information, your information will
      still be subject to the Policy in effect prior to the change. It is your
      responsibility to periodically review this Policy to stay informed about
      any updates.
      <h2 className='mt-4'>Data Collection</h2>
      We may collect personally identifiable information from you when you
      create a free account on the Site or when you communicate with us via
      phone, email, or chat. Additionally, we may gather information when you
      participate in contests, promotions, surveys, or events, or when you use
      the Gift Card service on the Site. This may include, but is not limited
      to, your name, gender, date of birth, email address, postal address,
      telephone number, payment details, and sensitive information such as
      password, physical health condition, or medical history. We collect,
      store, and process your data to facilitate your purchases on the Site,
      provide you with our services, and offer personalized experiences. When
      you visit the Site, our social media platforms, or engage with our social
      networking, we may also collect information about your device or usage
      through technologies such as cookies, web server logs, and web beacons.
      Please note that the Site is intended for use by adults aged 18 years and
      above. Minors should refrain from making purchases, registering, or
      submitting personal information on the Site. If you upload images to the
      website, please ensure they do not contain embedded location data (EXIF
      GPS), as visitors to the website may download and extract this
      information. Purposes for Collection of Personal and Sensitive Information
      We utilize the collected information for various purposes, including:
      <h2 className='mt-4'>Sending you the products you have ordered.</h2>
      Conducting marketing and performance research to enhance our services and
      product offerings. Processing Gift Card transactions. Providing
      information about us, the Site, our products, sales promotions,
      newsletters, and related topics. Sharing of Personal and Sensitive
      Information Only authorized personnel have access to your Personal and
      Sensitive Information. We may share this information with our affiliates
      and service providers who assist us in providing services or fulfilling
      orders. However, these service providers are contractually obligated to
      use or disclose such information only as necessary to perform services on
      our behalf or comply with legal requirements.
      <h2 className='mt-4'>Cookies</h2>
      We may use cookies to track your preferences and activities on the Site,
      making your visits more efficient. Cookies may store information such as
      the number of times you access the site, registration details, and your
      interaction with specific pages or items. Most web browsers are configured
      to accept cookies, but you can modify your settings to block them.
      However, please note that blocking cookies may impact certain
      functionalities of the Site.
      <h2 className='mt-4'>Security</h2>
      We are committed to maintaining the security, accuracy, and integrity of
      your Personal and Sensitive Information to the best of our ability.
      However, we cannot be held liable for any loss, damage, or harm resulting
      from unauthorized access or misuse of your information by third parties.
      <h2 className='mt-4'>Exchange/Return Policy</h2>
      <h3 className='mt-3'>Return Policy:</h3>
      You can return sealed items within a week of purchase. Returns will not be
      accepted for used products or requests made after the specified period.
      <h3 className='mt-3'>Product Issues:</h3>
      If you encounter any issues with your product, such as damage or defects,
      please report them on the same day of receipt with accompanying pictures
      for a replacement or refund. Exchanges: Sealed and unused items can be
      exchanged within 7 days of purchase. <h3 className='mt-3'>Procedure:</h3>
      Returns must be initiated by visiting our store. We strive to provide
      exceptional customer service and ensure your satisfaction with our
      products. <br />
      If you have any questions or concerns regarding our policies or practices,
      please don't hesitate to contact us. "
    </Container>
  );
}

export default PricacyPolicy;
