import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import ProductCard from '../ProductCard';

const LatestProducts = () => {
  const count = [1, 2, 3, 4, 5, 2, 2, 2];
  const [newArrival, setNewArrival] = useState([]);
  const [nLoading, setNLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const fProducts = await axios.get('/api/products/new');
      setNewArrival(fProducts.data);
      setNLoading(false);
    };
    if (nLoading) {
      fetch();
    }
  }, [nLoading]);

  return (
    <>
      <Container>
        <section className='layoutTitle'>
          <h1>Latest Products</h1>
        </section>
        <section>
          <Row>
            {newArrival.map((c, i) => (
              <ProductCard column={3} key={i} fProduct={c} />
            ))}
          </Row>
        </section>
      </Container>
    </>
  );
};

export default LatestProducts;
