import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MyDetails from '../../components/MyDetails';
import MyOrders from '../../components/MyOrders';
import { getSubscriberDetailsAsync, logout } from '../../redux/subscriberSlice';

const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, userInfo, isError, message } = useSelector(
    (state) => state.subscribers
  );

  const [accountTab, setAccountTab] = useState('details');
  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      if (!userInfo) {
        if (isError && message === 'Not authorize, token failed') {
          dispatch(logout());
        }
        dispatch(getSubscriberDetailsAsync());
      }
    }
  }, [user]);
  return (
    <>
      <Container>
        <section className='accountWrapper'>
          <Row>
            <Col lg={3} md={12}>
              <ul className='accountTabHolder'>
                <li
                  className='accountTabHolder-items'
                  onClick={(e) => {
                    e.preventDefault();
                    setAccountTab('details');
                  }}
                >
                  <i className='fa fa-user-o me-3'></i> Account Details
                </li>
                <li
                  className='accountTabHolder-items'
                  onClick={(e) => {
                    e.preventDefault();
                    setAccountTab('orders');
                  }}
                >
                  <i className='fa  fa-sticky-note-o me-3'></i> My Orders
                </li>
                <li
                  className='accountTabHolder-items'
                  onClick={() => dispatch(logout())}
                >
                  <i className='fa   fa-sliders me-3'></i> LogOut
                </li>
              </ul>
            </Col>

            <Col lg={8} md={12}>
              {accountTab === 'details' ? <MyDetails /> : <MyOrders />}
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default Account;
